import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";

router.beforeEach((to) => {
    let title = to.matched[0].meta.title + ""; // 获取到标题
    document.title = title; // 改变标题
});


createApp(App).use(NutUI).use(router).mount("#app");

