const Matrix = () => import('../../views/Matrix');
const MatrixZH = () => import('../../views/MatrixZH');


export default [
    {
        path: '/',
        name: 'index',
        component: Matrix,
        meta: {
            title: '微信矩阵'
        }
    },
    {
        path: '/matrix',
        name: 'matrixInfo',
        component: Matrix,
        meta: {
            title: '微信矩阵'
        }
    },
    {
        path: '/matrixZH',
        name: 'matrixZH',
        component: MatrixZH,
        meta: {
            title: '微信矩阵'
        }
    },
]
